import * as React from 'react'
import { Link } from 'gatsby'
import Layout from 'container/Layout'

const common = require('../data/common.json')


const NotFoundPage = () => {
  return (
    <Layout common={common}>
      <title>Not found</title>
      <h1>Page not found</h1>
      <p>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </Layout>
  )
}

export default NotFoundPage
